

.standard-create-btn{
	position: absolute;
	top: 51px;
	right: 20px;
	z-index: 999;
}

.page-title{
  padding: 10px 0px;
}

.navbar-inverse {
    color: rgba(255, 255, 255, 0.9) !important;
    background-color: #263238 !important;
}

select[multiple], select[size] {
    height: auto;
}

.media-left img:not(.media-preview), .media-right img:not(.media-preview), .thumbnail .media img:not(.media-preview){
	width: 24px !important;
	height: 24px !important;
}
.navigation-header:first-child{
	padding-top: 7px !important;
}
.navigation-header{
	border-bottom: 1px solid #666 !important;
}

.btn-xxs{
	padding: 4px 7px;
}
.btn-xxxs{
	padding: 2px 5px;
}
